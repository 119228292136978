import React, { useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'

const ClaimDropdown = ({ options, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className='relative'>
      <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
        {/* Arrow button */}
        <button onClick={() => setIsOpen(!isOpen)} className='w-8 h-8 flex items-center justify-center rounded-md bg-white/5 hover:bg-white/10 transition-all'>
          <img
            className={`${isOpen ? 'rotate-180' : 'rotate-0'} transform transition-all duration-300 w-4 h-4`}
            alt='dropdown'
            src='/images/liquidity/small-arrow-bottom.svg'
          />
        </button>

        {/* Dropdown menu */}
        {isOpen && (
          <div className='absolute right-0 mt-2 w-48 py-2 bg-[#252525] border border-[#ffffff33] rounded-xl shadow-lg z-50'>
            {options.map((option, idx) => (
              <button
                key={idx}
                onClick={() => {
                  onSelect(option.type)
                  setIsOpen(false)
                }}
                className='w-full px-4 py-2 text-left text-white hover:bg-themeOrange transition-colors duration-200 text-sm'
              >
                {option.display}
              </button>
            ))}
          </div>
        )}
      </OutsideClickHandler>
    </div>
  )
}

export default ClaimDropdown
