import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import useRefresh from './useRefresh'
import { useRewardsApi, useVeDist } from './useContract'
import { bribeAbi, rewardAPIAbi, veTHEAPIAbi } from '../config/abi'
import { multicall, multicallChunk } from '../utils/multicall'
import { fromWei, ZERO_ADDRESS } from '../utils/formatNumber'
import { getRewardsV3APIAddress, getVeTHEV3APIAddress } from '../utils/addressHelpers'
import BigNumber from 'bignumber.js'
import { BaseAssetsConetext } from '../context/BaseAssetsConetext'
import { useDispatch } from 'react-redux'
import { getBribeContract, getPairContract } from '../utils/contractHelpers'
import useWeb3, { useWeb3Wagmi } from './useWeb3'
import { v4 as uuidv4 } from 'uuid'
import { completeTransaction, openTransaction, updateTransaction } from '../state/transactions/actions'
import { gaugeBlacklist, TransactionType } from '../config/constants'
import { sendContract } from '../utils/api'
import { FusionsContext } from '../context/FusionsContext'
import { useV3Voter } from './useContract'
import { veTHEsContext } from '../context/veTHEsConetext'
import { chunk } from 'lodash'
import { trackRewards } from '../utils/tracking'

const DEI = '0xde1e704dae0b4051e80dabb26ab6ad6c12262da0'

const useGetVeRewards = (veTHE) => {
  const [rewards, setRewards] = useState([])
  const [init, setInit] = useState(false)
  const [loadedId, setLoadedId] = useState(0)
  const fusions = useContext(FusionsContext)
  const baseAssets = useContext(BaseAssetsConetext)
  const running = useRef(false)

  useEffect(() => {
    const fetchRewards = async () => {
      try {
        running.current = true
        const gauges = fusions.filter((pool) => pool.gauge.address !== ZERO_ADDRESS && !gaugeBlacklist.includes(pool.gauge.address.toLowerCase()))
        const callsRewards = gauges.map((pool) => {
          return {
            address: getVeTHEV3APIAddress(),
            name: 'singlePairReward',
            params: [veTHE.id, pool.address],
          }
        })
        const batchSizes = [10, 5, 2, 1]
        let resRewards = []
        for (const batchSize of batchSizes) {
          try {
            resRewards = await multicallChunk(veTHEAPIAbi, callsRewards, batchSize)
            break
          } catch (e) {
            if (batchSize === batchSizes[batchSizes.length - 1]) {
              console.error(`Error in multicall chunk even with smallest batch size (${batchSize}):`, e)
              throw e
            }
          }
        }
        const final = gauges
          .map((pool, index) => {
            const fees = {}
            const bribes = {}
            let isFeeExist = false
            let isBribeExist = false
            resRewards[index][0].forEach((reward, idx) => {
              const { 1: amount, 2: decimals, 4: address } = reward
              if (Number(amount) > 0) {
                if (idx < 2) {
                  isFeeExist = true
                  fees[address] = {
                    address,
                    amount: !fees[address] ? fromWei(Number(amount), decimals) : fees[address]['amount'].plus(fromWei(Number(amount), decimals)),
                  }
                } else {
                  isBribeExist = true
                  bribes[address] = {
                    address,
                    amount: !bribes[address] ? fromWei(Number(amount), decimals) : bribes[address]['amount'].plus(fromWei(Number(amount), decimals)),
                  }
                }
              }
            })

            const finalFees = Object.values(fees).map((fee) => {
              const found = baseAssets.find((ele) => ele.address.toLowerCase() === fee.address.toLowerCase())
              return {
                ...fee,
                symbol: found?.symbol,
              }
            })

            const finalBribes = Object.values(bribes).map((bribe) => {
              const found = baseAssets.find((ele) => ele.address.toLowerCase() === bribe.address.toLowerCase())
              return {
                ...bribe,
                symbol: found?.symbol,
              }
            })

            return {
              ...pool,
              fees: finalFees,
              rewards: finalBribes,
              isFeeExist,
              isBribeExist,
            }
          })
          .filter((pool) => pool.isFeeExist || pool.isBribeExist)
          .map((pool) => {
            let votes = {
              weight: new BigNumber(0),
              weightPercent: new BigNumber(0),
            }
            if (veTHE.votes.length > 0) {
              const found = veTHE.votes.find((ele) => ele.address.toLowerCase() === pool.address.toLowerCase())
              if (found) {
                votes = found
              }
            }
            let totalUsd = new BigNumber(0)
            const finalRewards = pool.rewards.map((reward) => {
              const found = baseAssets.find((ele) => ele.address.toLowerCase() === reward.address.toLowerCase())
              if (found) {
                totalUsd = totalUsd.plus(reward.amount.times(found.price))
                return {
                  ...reward,
                  symbol: found.symbol,
                }
              }
              return reward
            })
            pool.fees.forEach((fee) => {
              const found = baseAssets.find((ele) => ele.address.toLowerCase() === fee.address.toLowerCase())
              if (found) {
                totalUsd = totalUsd.plus(fee.amount.times(found.price))
              }
            })
            return {
              ...pool,
              rewards: finalRewards,
              totalUsd,
              votes,
            }
          })
        setRewards(final)
        setInit(true)
        setLoadedId(veTHE.id)
        running.current = false
      } catch (error) {
        console.log('current rewards error :>> ', error)
        setRewards([])
      }
    }

    if (fusions.length > 0 && veTHE && (!init || loadedId != veTHE?.id) && !running.current) {
      fetchRewards()
    }
  }, [fusions, baseAssets, veTHE?.id, init, loadedId])

  return { rewards, setInit, init, loadedId }
}

const useGetSelectedVeRewards = (veTHE, selected, allRewards) => {
  let selectedRewards
  if (allRewards && selected && selected.length > 0) {
    selectedRewards = allRewards.filter((gauge) => selected.includes(gauge.address))
  }

  return selectedRewards
}

const useGetAllVeRewards = () => {
  const [rewards, setRewards] = useState([])
  const [init, setInit] = useState(false)
  const { veTHEs } = useContext(veTHEsContext)
  const fusions = useContext(FusionsContext)
  const baseAssets = useContext(BaseAssetsConetext)
  const running = useRef(false)

  useEffect(() => {
    const fetchRewards = async () => {
      const result = []
      try {
        running.current = true
        for (const veTHE of veTHEs) {
          const gauges = fusions.filter((pool) => pool.gauge.address !== ZERO_ADDRESS)
          const callsRewards = gauges.map((pool) => {
            return {
              address: getVeTHEV3APIAddress(),
              name: 'singlePairReward',
              params: [veTHE.id, pool.address],
            }
          })
          // TODO: Proper progressive backoff
          let resRewards
          try {
            resRewards = await multicallChunk(veTHEAPIAbi, callsRewards, 6)
          } catch (e) {
            resRewards = await multicallChunk(veTHEAPIAbi, callsRewards, 2)
          }
          const final = gauges
            .map((pool, index) => {
              const fees = {}
              const bribes = {}
              let isFeeExist = false
              let isBribeExist = false
              resRewards[index][0].forEach((reward, idx) => {
                const { 1: amount, 2: decimals, 4: address } = reward
                if (Number(amount) > 0) {
                  if (idx < 2) {
                    isFeeExist = true
                    fees[address] = {
                      address,
                      amount: !fees[address] ? fromWei(Number(amount), decimals) : fees[address]['amount'].plus(fromWei(Number(amount), decimals)),
                    }
                  } else {
                    isBribeExist = true
                    bribes[address] = {
                      address,
                      amount: !bribes[address] ? fromWei(Number(amount), decimals) : bribes[address]['amount'].plus(fromWei(Number(amount), decimals)),
                    }
                  }
                }
              })

              const finalFees = Object.values(fees).map((fee) => {
                const found = baseAssets.find((ele) => ele.address.toLowerCase() === fee.address.toLowerCase())
                return {
                  ...fee,
                  symbol: found?.symbol,
                }
              })

              const finalBribes = Object.values(bribes).map((bribe) => {
                const found = baseAssets.find((ele) => ele.address.toLowerCase() === bribe.address.toLowerCase())
                return {
                  ...bribe,
                  symbol: found?.symbol,
                }
              })

              return {
                ...pool,
                fees: finalFees,
                rewards: finalBribes,
                isFeeExist,
                isBribeExist,
              }
            })
            .filter((pool) => pool.isFeeExist || pool.isBribeExist)
            .map((pool) => {
              let votes = {
                weight: new BigNumber(0),
                weightPercent: new BigNumber(0),
              }
              if (veTHE.votes.length > 0) {
                const found = veTHE.votes.find((ele) => ele.address.toLowerCase() === pool.address.toLowerCase())
                if (found) {
                  votes = found
                }
              }
              let totalUsd = new BigNumber(0)
              const finalRewards = pool.rewards.map((reward) => {
                const found = baseAssets.find((ele) => ele.address.toLowerCase() === reward.address.toLowerCase())
                if (found) {
                  totalUsd = totalUsd.plus(reward.amount.times(found.price))
                  return {
                    ...reward,
                    symbol: found.symbol,
                  }
                }
                return reward
              })
              pool.fees.forEach((fee) => {
                const found = baseAssets.find((ele) => ele.address.toLowerCase() === fee.address.toLowerCase())
                if (found) {
                  totalUsd = totalUsd.plus(fee.amount.times(found.price))
                }
              })
              return {
                ...pool,
                rewards: finalRewards,
                totalUsd,
                votes,
              }
            })
          result.push(final)
        }
        setRewards(result)
        setInit(true)
        running.current = false
      } catch (error) {
        console.log('current rewards error :>> ', error)
        setRewards([])
      }
    }

    if (fusions.length > 0 && veTHEs.length > 0 && !init && !running.current) {
      fetchRewards()
    }
  }, [fusions, baseAssets, veTHEs, init])

  return { rewards, init, setInit }
}

const useExpectedRewards = (veTHE) => {
  const [rewards, setRewards] = useState([])
  const fusions = useContext(FusionsContext)
  const baseAssets = useContext(BaseAssetsConetext)
  const rewardsApiContract = useRewardsApi()

  useEffect(() => {
    const fetchRewards = async () => {
      try {
        const gaugePools = fusions.filter((pool) => pool.gauge.address !== ZERO_ADDRESS)
        const callsRewards = gaugePools.map((pool) => {
          const arr = []
          arr.push(pool.address)
          return {
            address: getRewardsV3APIAddress(),
            name: 'getExpectedClaimForNextEpoch',
            params: [veTHE.id, arr],
          }
        })
        const resRewards = await multicall(rewardAPIAbi, callsRewards)
        const final = gaugePools
          .map((pool, index) => {
            let result = {}
            // bribes
            const { 0: tokens, 2: decimals, 3: amounts } = resRewards[index][0][0][0][0]
            tokens.map((token, index) => {
              if (Number(amounts[index]) > 0) {
                result[token] = {
                  address: token,
                  amount: !result[token]
                    ? fromWei(Number(amounts[index]), Number(decimals[index]))
                    : result[token]['amount'].plus(fromWei(Number(amounts[index]), Number(decimals[index]))),
                }
              }
            })

            // fees
            const { 0: feeTokens, 2: feeDecimals, 3: feeAmounts } = resRewards[index][0][0][0][1]
            feeTokens.map((token, index) => {
              if (Number(feeAmounts[index]) > 0) {
                result[token] = {
                  address: token,
                  amount: !result[token]
                    ? fromWei(Number(feeAmounts[index]), Number(feeDecimals[index]))
                    : result[token]['amount'].plus(fromWei(Number(feeAmounts[index]), Number(feeDecimals[index]))),
                }
              }
            })
            return {
              ...pool,
              rewards: Object.values(result),
            }
          })
          .filter((pool) => pool.rewards.length > 0)
          .map((pool) => {
            let totalUsd = new BigNumber(0)
            const finalRewards = pool.rewards.map((reward) => {
              const found = baseAssets.find((ele) => ele.address.toLowerCase() === reward.address.toLowerCase())
              if (found) {
                totalUsd = totalUsd.plus(reward.amount.times(found.price))
                return {
                  ...reward,
                  symbol: found.symbol,
                }
              }
              return reward
            })
            return {
              ...pool,
              rewards: finalRewards,
              totalUsd,
            }
          })
        setRewards(final)
      } catch (error) {
        console.log('expected rewards error :>> ', error)
        setRewards([])
      }
    }

    if (fusions.length > 0 && veTHE) {
      fetchRewards()
    } else {
      setRewards([])
    }
  }, [fusions, rewardsApiContract, baseAssets, veTHE])

  return rewards
}

const useAllExpectedRewards = () => {
  const [rewards, setRewards] = useState([])
  const { fastRefresh } = useRefresh()
  const { veTHEs } = useContext(veTHEsContext)
  const fusions = useContext(FusionsContext)
  const baseAssets = useContext(BaseAssetsConetext)
  const rewardsApiContract = useRewardsApi()

  useEffect(() => {
    const fetchRewards = async () => {
      const result = []
      try {
        for (const veTHE of veTHEs) {
          const gaugePools = fusions.filter((pool) => pool.gauge.address !== ZERO_ADDRESS)
          const callsRewards = gaugePools.map((pool) => {
            const arr = []
            arr.push(pool.address)
            return {
              address: getRewardsV3APIAddress(),
              name: 'getExpectedClaimForNextEpoch',
              params: [veTHE.id, arr],
            }
          })
          const resRewards = await multicall(rewardAPIAbi, callsRewards)
          const final = gaugePools
            .map((pool, index) => {
              let result = {}
              // bribes
              const { 0: tokens, 2: decimals, 3: amounts } = resRewards[index][0][0][0][0]
              tokens.map((token, index) => {
                if (Number(amounts[index]) > 0) {
                  result[token] = {
                    address: token,
                    amount: !result[token]
                      ? fromWei(Number(amounts[index]), Number(decimals[index]))
                      : result[token]['amount'].plus(fromWei(Number(amounts[index]), Number(decimals[index]))),
                  }
                }
              })

              // fees
              const { 0: feeTokens, 2: feeDecimals, 3: feeAmounts } = resRewards[index][0][0][0][1]
              feeTokens.map((token, index) => {
                if (Number(feeAmounts[index]) > 0) {
                  result[token] = {
                    address: token,
                    amount: !result[token]
                      ? fromWei(Number(feeAmounts[index]), Number(feeDecimals[index]))
                      : result[token]['amount'].plus(fromWei(Number(feeAmounts[index]), Number(feeDecimals[index]))),
                  }
                }
              })
              return {
                ...pool,
                rewards: Object.values(result),
              }
            })
            .filter((pool) => pool.rewards.length > 0)
            .map((pool) => {
              let totalUsd = new BigNumber(0)
              const finalRewards = pool.rewards.map((reward) => {
                const found = baseAssets.find((ele) => ele.address.toLowerCase() === reward.address.toLowerCase())
                if (found) {
                  totalUsd = totalUsd.plus(reward.amount.times(found.price))
                  return {
                    ...reward,
                    symbol: found.symbol,
                  }
                }
                return reward
              })
              return {
                ...pool,
                rewards: finalRewards,
                totalUsd,
              }
            })
          result.push(final)
        }
        setRewards(result)
      } catch (error) {
        console.log('expected rewards error :>> ', error)
        setRewards([])
      }
    }

    if (fusions.length > 0 && veTHEs.length > 0) {
      fetchRewards()
    } else {
      setRewards([])
    }
  }, [fusions, rewardsApiContract, baseAssets, fastRefresh, veTHEs])

  return rewards
}

const useGetFees = () => {
  const [fees, setFees] = useState([])
  const { account } = useWeb3Wagmi()
  const fusions = useContext(FusionsContext)
  const baseAssets = useContext(BaseAssetsConetext)

  useEffect(() => {
    const fetchRewards = () => {
      try {
        const result = fusions
          .filter((pool) => !pool.account.token0claimable.isZero() || !pool.account.token1claimable.isZero())
          .map((pool) => {
            const found0 = baseAssets.find((ele) => ele.address.toLowerCase() === pool.token0.address.toLowerCase())
            const found1 = baseAssets.find((ele) => ele.address.toLowerCase() === pool.token1.address.toLowerCase())
            const totalUsd = pool.account.token0claimable.times(found0?.price).plus(pool.account.token1claimable.times(found1?.price))
            return {
              ...pool,
              totalUsd,
            }
          })
        setFees(result)
      } catch (error) {
        console.log('fees error :>> ', error)
        setFees([])
      }
    }

    if (fusions.length > 0 && account) {
      fetchRewards()
    } else {
      setFees([])
    }
  }, [account, fusions, baseAssets])

  return fees
}

const useClaimBribes = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const web3 = useWeb3()

  const handleClaimBribes = useCallback(
    async (pool, veTHE, onlyBribes = false) => {
      const key = uuidv4()

      const callsFees =
        pool.fees?.map((fee) => {
          return {
            address: pool.gauge.fee,
            name: 'earned',
            params: [veTHE.id, fee.address],
          }
        }) || []

      const callsBribes =
        pool.rewards?.map((reward) => {
          return {
            address: pool.gauge.bribe,
            name: 'earned',
            params: [veTHE.id, reward.address],
          }
        }) || []

      const [resFees, resBribes] = await Promise.all([onlyBribes ? Promise.resolve([]) : multicall(bribeAbi, callsFees), multicall(bribeAbi, callsBribes)])

      const feeTokens = []
      if (!onlyBribes) {
        resFees.forEach((item, index) => {
          const feeTokenAddress = pool.fees[index].address.toLowerCase()
          if (DEI !== feeTokenAddress && Number(item) > 0) {
            feeTokens.push(pool.fees[index].address)
          }
        })
      }

      const bribeTokens = []
      resBribes.forEach((item, index) => {
        const rewardTokenAddress = pool.rewards[index].address.toLowerCase()
        if (DEI !== rewardTokenAddress && Number(item) > 0) {
          bribeTokens.push(pool.rewards[index].address)
        }
      })

      const result = {}
      const bribesuuid = uuidv4()
      const feeuuid = uuidv4()

      if (bribeTokens.length > 0) {
        result[bribesuuid] = {
          desc: `Claim Bribes`,
          status: TransactionType.START,
          hash: null,
        }
      }

      if (feeTokens.length > 0) {
        result[feeuuid] = {
          desc: `Claim Fees`,
          status: TransactionType.START,
          hash: null,
        }
      }

      dispatch(
        openTransaction({
          key,
          title: onlyBribes ? `Claim Bribes for ${pool.symbol}` : `Claim Bribes + Fees for ${pool.symbol}`,
          transactions: result,
        }),
      )

      const chunkSize = 3
      const claimTokens = async (tokens, contractAddress, uuid, name) => {
        if (tokens.length > 0) {
          const bribeContract = getBribeContract(web3, contractAddress)
          const tokenChunks = chunk(tokens, chunkSize)
          for (const tokenChunk of tokenChunks) {
            const params = [veTHE.id, tokenChunk]
            setPending(true)
            try {
              await sendContract(dispatch, key, uuid, bribeContract, 'getReward', params, account)
            } catch (err) {
              console.log(`${name} claim error :>> `, err)
              setPending(false)
              return false
            }
          }
        }
        return true
      }

      const bribeSuccess = await claimTokens(bribeTokens, pool.gauge.bribe, bribesuuid, 'bribes')
      if (!bribeSuccess) return

      if (!onlyBribes) {
        const feeSuccess = await claimTokens(feeTokens, pool.gauge.fee, feeuuid, 'fees')
        if (!feeSuccess) return
      }

      dispatch(
        completeTransaction({
          key,
          final: onlyBribes ? 'Claimed Bribes' : 'Claimed Bribes + Fees',
        }),
      )
      setPending(false)
      trackRewards({ walletAddress: account })
    },
    [account, web3],
  )

  return { onClaimBribes: handleClaimBribes, pending }
}

const useClaimFees = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const web3 = useWeb3()

  const handleClaimFees = useCallback(
    async (pool) => {
      const pairContract = getPairContract(web3, pool.address)
      const key = uuidv4()
      const harvestuuid = uuidv4()
      dispatch(
        openTransaction({
          key,
          title: `Claim fees for ${pool.symbol}`,
          transactions: {
            [harvestuuid]: {
              desc: `Claim fees`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )
      const params = []
      setPending(true)
      try {
        await sendContract(dispatch, key, harvestuuid, pairContract, 'claimFees', params, account)
      } catch (err) {
        console.log('fees claim error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Claimed Fees',
        }),
      )
      setPending(false)
      trackRewards({ walletAddress: account })
    },
    [account, web3],
  )

  return { onClaimFees: handleClaimFees, pending }
}

const useClaimRebase = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const veDist = useVeDist()

  const handleClaimRebase = useCallback(
    async (veTHE) => {
      const key = uuidv4()
      const veClaimuuid = uuidv4()
      dispatch(
        openTransaction({
          key,
          title: `Claim rebase for veLYNX #${veTHE.id}`,
          transactions: {
            [veClaimuuid]: {
              desc: `Claim rebase`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )
      const params = [veTHE.id]
      setPending(true)
      try {
        await sendContract(dispatch, key, veClaimuuid, veDist, 'claim', params, account)
      } catch (err) {
        console.log('rebase claim error :>> ', err)
        setPending(false)
        return
      }

      dispatch(
        completeTransaction({
          key,
          final: 'Claimed rebase',
        }),
      )
      setPending(false)
      trackRewards({ walletAddress: account, rebase: true })
    },
    [account, veDist],
  )

  return { onClaimRebase: handleClaimRebase, pending }
}

const CLAIM_ALL_CHUNKS = 7
const useClaimAll = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const web3 = useWeb3()
  const veDistContract = useVeDist()
  const voterContract = useV3Voter()

  const handleClaimAll = useCallback(
    async (veRewards, veTHE, selected, onSuccessSelected, amountUSD) => {
      const key = uuidv4()
      const bribesuuid = uuidv4()
      const feeuuid = uuidv4()
      const veuuid = uuidv4()
      const bribeRewards = veRewards.filter((item) => item.isBribeExist)
      const feeRewards = veRewards.filter((item) => item.isFeeExist)
      const bribesChunksAmount = Math.ceil(bribeRewards.length / CLAIM_ALL_CHUNKS)
      const feesChunksAmount = Math.ceil(feeRewards.length / CLAIM_ALL_CHUNKS)

      if (selected) {
        dispatch(
          openTransaction({
            key,
            title: `Claim Selected Rewards for veLYNX #${veTHE.id}`,
            transactions: {
              [bribesuuid]: {
                desc: `Claim bribes` + (bribesChunksAmount > 1 ? ` (${bribesChunksAmount} TXs)` : ''),
                status: TransactionType.START,
                hash: null,
              },
              [feeuuid]: {
                desc: `Claim fees` + (feesChunksAmount > 1 ? ` (${feesChunksAmount} TXs)` : ''),
                status: TransactionType.START,
                hash: null,
              },
            },
          }),
        )
      } else {
        dispatch(
          openTransaction({
            key,
            title: `Claim All Rewards for veLYNX #${veTHE.id}`,
            transactions: {
              [bribesuuid]: {
                desc: `Claim bribes` + (bribesChunksAmount > 1 ? ` (${bribesChunksAmount} TXs)` : ''),
                status: TransactionType.START,
                hash: null,
              },
              [feeuuid]: {
                desc: `Claim fees` + (feesChunksAmount > 1 ? ` (${feesChunksAmount} TXs)` : ''),
                status: TransactionType.START,
                hash: null,
              },
              [veuuid]: {
                desc: `Claim rebase`,
                status: TransactionType.START,
                hash: null,
              },
            },
          }),
        )
      }

      setPending(true)

      if (bribeRewards.length > 0) {
        const bribes = bribeRewards.map((item) => item.gauge.bribe)
        const bribeTokens = bribeRewards.map((item) => {
          return item.rewards.map((token) => token.address).filter((ele) => ele.toLowerCase() !== DEI)
        })
        const bribeChunks = chunk(bribes, CLAIM_ALL_CHUNKS)
        const tokensChunks = chunk(bribeTokens, CLAIM_ALL_CHUNKS)
        const bribePromises = []
        for (let i = 0; i < bribeChunks.length; i++) {
          const bribeParams = [bribeChunks[i], tokensChunks[i], veTHE.id]
          bribePromises.push(sendContract(dispatch, key, bribesuuid, voterContract, 'claimBribes', bribeParams, account))
        }
        try {
          await Promise.all(bribePromises)
        } catch (err) {
          console.log('bribes claim error :>> ', err)
          setPending(false)
          return
        }
      } else {
        dispatch(
          updateTransaction({
            key,
            uuid: bribesuuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }

      if (feeRewards.length > 0) {
        const fees = feeRewards.map((item) => item.gauge.fee)
        const feeTokens = feeRewards.map((item) => {
          return item.fees.map((token) => token.address).filter((ele) => ele.toLowerCase() !== DEI)
        })
        const feeChunks = chunk(fees, CLAIM_ALL_CHUNKS)
        const tokensChunks = chunk(feeTokens, CLAIM_ALL_CHUNKS)
        const feesPromises = []
        for (let i = 0; i < feeChunks.length; i++) {
          const bribeParams = [feeChunks[i], tokensChunks[i], veTHE.id]
          feesPromises.push(sendContract(dispatch, key, feeuuid, voterContract, 'claimFees', bribeParams, account))
        }
        try {
          await Promise.all(feesPromises)
        } catch (err) {
          console.log('fees claim error :>> ', err)
          setPending(false)
          return
        }
      } else {
        dispatch(
          updateTransaction({
            key,
            uuid: feeuuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }

      if (veTHE.rebase_amount.gt(0) && !selected) {
        const params = [veTHE.id]
        try {
          await sendContract(dispatch, key, veuuid, veDistContract, 'claim', params, account)
        } catch (err) {
          console.log('rebase claim error :>> ', err)
          setPending(false)
          return
        }
      } else {
        dispatch(
          updateTransaction({
            key,
            uuid: veuuid,
            status: TransactionType.SUCCESS,
          }),
        )
      }

      selected && onSuccessSelected()
      dispatch(
        completeTransaction({
          key,
          final: selected ? 'Claimed Selected Rewards' : 'Claimed All Rewards',
        }),
      )
      setPending(false)
      trackRewards({ walletAddress: account, amountUSD })
    },
    [account, web3, veDistContract, voterContract],
  )

  return { onClaimAll: handleClaimAll, pending }
}

const useClaimBribesSingleToken = () => {
  const [pending, setPending] = useState(false)
  const { account } = useWeb3Wagmi()
  const dispatch = useDispatch()
  const web3 = useWeb3()

  const handleClaimBribesSingleToken = useCallback(
    async (pool, tokenAddress, veTHE) => {
      const bribeToken = pool.rewards.find((r) => r.address === tokenAddress)
      const tokenSymbol = bribeToken?.symbol || 'Unknown'
      const key = uuidv4()
      const claimUuid = uuidv4()

      dispatch(
        openTransaction({
          key,
          title: `Claim ${tokenSymbol} bribes for ${pool.symbol}`,
          transactions: {
            [claimUuid]: {
              desc: `Claim ${tokenSymbol}`,
              status: TransactionType.START,
              hash: null,
            },
          },
        }),
      )

      setPending(true)
      try {
        const bribeContract = getBribeContract(web3, pool.gauge.bribe)
        const params = [veTHE.id, [tokenAddress]]
        await sendContract(dispatch, key, claimUuid, bribeContract, 'getReward', params, account)

        dispatch(
          completeTransaction({
            key,
            final: `Claimed ${tokenSymbol} bribes`,
          }),
        )
      } catch (err) {
        console.log('single bribe claim error :>> ', err)
      }
      setPending(false)
      trackRewards({ walletAddress: account })
    },
    [account, web3],
  )

  return { onClaimBribesSingleToken: handleClaimBribesSingleToken, pending }
}

export {
  useGetAllVeRewards,
  useGetSelectedVeRewards,
  useAllExpectedRewards,
  useGetVeRewards,
  useExpectedRewards,
  useGetFees,
  useClaimBribes,
  useClaimFees,
  useClaimRebase,
  useClaimAll,
  useClaimBribesSingleToken,
}
